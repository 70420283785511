/**
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/26/20, 8:47 PM
 *
 */

import { useEffect, useMemo, useState } from "react";
import Loader2 from "../../../utilities/Loader2";
import ModalDialog from "../../../../layout/modal-dialog";
import SelectNeoGen from "../../../../layout/select-neogen";
import { User } from "../../../../typings/api";
import InputControlled from "../../../../layout/input-controlled";
import { ErcUser } from "../../../../typings/api/erc-user";
import { useQuery } from "@tanstack/react-query";
import usersService from "../../../../services/users.service";
import roleGroupService, { roleAssignments, roleGroupTypes } from "../../../../services/role-group.service";
import { RoleGroup } from "../../../../typings/api/role-group";
import authService from "../../../../services/auth.service";
import { Select } from "../../../../layout/form/select-input";

function compare(a: User, b: User) {
    if (a.email.toLowerCase() < b.email.toLowerCase()) {
        return -1;
    }
    if (a.email.toLowerCase() > b.email.toLowerCase()) {
        return 1;
    }
    return 0;
}

function AddEditUserModal(props: AddEditUserModalProps) {
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [phone, setPhone] = useState("");
    const [stPass, setStPass] = useState("");
    const [stUrl, setStUrl] = useState("");
    const [stCompany, setStCompany] = useState(-1);
    const [company, setCompany] = useState(-1);
    const [cpa, setCpa] = useState("");
    const [affiliate, setAffiliate] = useState<string>("");
    const [taxAttorney, setTaxAttorney] = useState("");
    const [manager, setManager] = useState("");
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("");
    const [roleGroup, setRoleGroup] = useState<RoleGroup | undefined>();

    const usersQuery = useQuery(["users"], async () => {
        const response = await usersService.getAll();
        if (response) {
            return response.data.sort(compare);
        }
    });

    const affiliatesQuery = useQuery(["users", "affiliates", props.userBeingEdited?.id], async () => {
        const response = await usersService.getAll({ filters: { role: "Affiliate" } });
        return response?.data || [];
    });
    const affiliates = useMemo(() => affiliatesQuery.data || [], [affiliatesQuery.data]);

    const accountantsQuery = useQuery(["users", "accountant", props.userBeingEdited?.id], async () => {
        const response = await usersService.getAll({ filters: { role: "Accountant" } });
        return response?.data || [];
    });
    const accountants = useMemo(() => accountantsQuery.data || [], [accountantsQuery.data]);

    const roleGroupsQuery = useQuery(["roleGroups"], async () => {
        const response = await roleGroupService.getAll();
        return response?.data || [];
    });

    const allRoleGroups = roleGroupsQuery.data || [];
    const usersRoleGroups = (authService.getCurrentUser()?.user?.roleGroups || []) as any[];
    const roleGroupsIds = usersRoleGroups.reduce<number[]>((acc, roleGroup: any) => {
        return [...acc, ...(roleAssignments?.[roleGroup.id] || [])];
    }, []);
    const roleGroups = allRoleGroups.filter((rg) => roleGroupsIds.includes(rg.id || 9999));

    const isSuperUser = !!usersRoleGroups.find((rg) => rg.id === roleGroupTypes.SuperUser);

    const reset = () => {
        setEmail("");
        setName("");
        setCompany(-1);
        setFirstName("");
        setLastName("");
        setPhone("");
        setStPass("");
        setStUrl("");
        setStCompany(-1);
        setCpa("");
        setAffiliate("");
        setTaxAttorney("");
        setManager("");
        setRoleGroup(undefined);
        setPassword("");
        setPasswordAgain("");
    };

    useEffect(() => {
        if (!props.isEdit) {
            reset();
        } else {
            if (props.userBeingEdited) {
                setEmail(props.userBeingEdited.email ?? "");
                setName(props.userBeingEdited.name ?? "");
                setFirstName(props.userBeingEdited.firstName ?? "");
                setLastName(props.userBeingEdited.lastName ?? "");
                setPhone(props.userBeingEdited.phone ?? "");
                setStPass(props.userBeingEdited.stPass ?? "");
                setStUrl(props.userBeingEdited.stUrl ?? "");
                setStCompany(props.userBeingEdited.stCompany ?? -1);
                setCompany(props.userBeingEdited.company ?? -1);
                setCpa(props.userBeingEdited.cpaUserId ?? "");
                setAffiliate(props.userBeingEdited.affiliateUserId ?? "");
                setTaxAttorney(props.userBeingEdited.taxAttorneyUserId ?? "");
                setManager(props.userBeingEdited.accountManagerUserId ?? "");
            }
        }
    }, [props.isEdit, props.userBeingEdited]);

    function saveUser() {
        props.saveUser(
            props.isEdit,
            email,
            name,
            company,
            firstName,
            lastName,
            phone,
            props.userBeingEdited,
            stPass,
            stUrl,
            stCompany,
            password,
            cpa,
            manager,
            affiliate,
            taxAttorney,
            roleGroup,
        );
        reset();
    }

    if (usersQuery.isLoading) {
        // Not possible because it will be cached by the users page
        return <Loader2 />;
    }

    return (
        <>
            <ModalDialog
                size="md"
                okText="Save"
                okAction={() => saveUser()}
                show={props.show}
                close={() => {
                    props.handleClose(false);
                    reset();
                }}
                title={
                    props.isEdit
                        ? props.userBeingEdited === null
                            ? "Loading..."
                            : "Editing: " + props.userBeingEdited.email
                        : "New User"
                }
            >
                <div className="">
                    {!((props.userBeingEdited === null && props.isEdit) || props.saving) ? (
                        <div className="grid grid-cols-3 gap-5">
                            <div className="col-span-3 grid grid-cols-2 gap-5">
                                <InputControlled
                                    label="Email"
                                    type="text"
                                    value={email}
                                    noMargin
                                    setValue={(e) => setEmail(e)}
                                    placeholder="you@example.com"
                                />
                            </div>

                            {!props.isEdit && (
                                <div className="col-span-3 grid grid-cols-2 gap-5">
                                    <InputControlled
                                        label="Password"
                                        type="password"
                                        value={password}
                                        setValue={(e) => setPassword(e)}
                                        placeholder="Password"
                                        className=""
                                        noMargin
                                        autoComplete="new-password"
                                    />
                                    <InputControlled
                                        label="Password again"
                                        type="password"
                                        value={passwordAgain}
                                        setValue={(e) => setPasswordAgain(e)}
                                        placeholder="Password again"
                                        className=""
                                        noMargin
                                        autoComplete="new-password"
                                    />
                                </div>
                            )}
                            <div className="col-span-3 grid grid-cols-2 gap-5">
                                <InputControlled
                                    label="Company name"
                                    type="text"
                                    value={name}
                                    noMargin
                                    setValue={(e) => setName(e)}
                                    placeholder="Company name"
                                />
                                <InputControlled
                                    label="Phone"
                                    type="text"
                                    value={phone}
                                    noMargin
                                    setValue={(e) => setPhone(e)}
                                    placeholder="Phone"
                                />
                            </div>

                            <div className="col-span-3 grid grid-cols-2 gap-5">
                                <InputControlled
                                    label="First name"
                                    type="text"
                                    value={firstName}
                                    setValue={(e) => setFirstName(e)}
                                    placeholder="First name"
                                />
                                <InputControlled
                                    label="Last name"
                                    type="text"
                                    value={lastName}
                                    setValue={(e) => setLastName(e)}
                                    placeholder="Last name"
                                />
                            </div>

                            {!props.isEdit && (
                                <div className="col-span-3">
                                    <SelectNeoGen
                                        options={roleGroups.map((roleGroup) => ({
                                            id: roleGroup.id,
                                            name: roleGroup.name,
                                        }))}
                                        label="Role"
                                        value={roleGroup?.id}
                                        onChange={(roleGroupId) => {
                                            const roleGroupToSet = roleGroups.find(
                                                (roleGroup) => roleGroup.id === roleGroupId,
                                            );
                                            if (!roleGroupToSet) {
                                                throw new Error(`Role group with id ${roleGroupId} not found`);
                                            }
                                            setRoleGroup(roleGroupToSet);
                                        }}
                                    />
                                </div>
                            )}

                            <div className="col-span-3">
                                <Select
                                    label="Affiliate"
                                    options={affiliates.map((user) => ({
                                        value: user.id || "no-id",
                                        label: `${[user.firstName, user.lastName].join(" ") || "No name"} (${
                                            user.email
                                        })`,
                                    }))}
                                    value={affiliate || ""}
                                    onChange={(id) => {
                                        setAffiliate(id && !Array.isArray(id) ? id : "");
                                    }}
                                />
                            </div>
                            {isSuperUser && (
                                <div className="col-span-3 grid grid-cols-3 gap-5">
                                    <div className="my-5">
                                        <Select
                                            label="Tax Attorney"
                                            options={(usersQuery.data || []).map((user) => ({
                                                value: user.id || "no-id",
                                                label: `${[user.firstName, user.lastName].join(" ") || "No name"} (${
                                                    user.email
                                                })`,
                                            }))}
                                            value={taxAttorney || ""}
                                            onChange={(id) => {
                                                setTaxAttorney(id && !Array.isArray(id) ? id : "");
                                            }}
                                        />
                                    </div>
                                    <div className="my-5">
                                        <Select
                                            label="Accountant"
                                            options={accountants.map((user) => ({
                                                value: user.id || "no-id",
                                                label: `${[user.firstName, user.lastName].join(" ") || "No name"} (${
                                                    user.email
                                                })`,
                                            }))}
                                            value={cpa || ""}
                                            onChange={(id) => {
                                                setCpa(id && !Array.isArray(id) ? id : "");
                                            }}
                                        />
                                    </div>
                                    <div className="my-5">
                                        <Select
                                            label="Doc Collector"
                                            options={(usersQuery.data || []).map((user) => ({
                                                value: user.id || "no-id",
                                                label: `${[user.firstName, user.lastName].join(" ") || "No name"} (${
                                                    user.email
                                                })`,
                                            }))}
                                            value={manager || ""}
                                            onChange={(id) => {
                                                setManager(id && !Array.isArray(id) ? id : "");
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div className={" pt-5 mb-5 pb-5 text-center"}>
                            <Loader2 />
                        </div>
                    )}
                </div>
            </ModalDialog>
        </>
    );
}

type AddEditUserModalProps = {
    handleClose: (x: boolean) => void;
    show: boolean;
    userBeingEdited: ErcUser | null;
    // roles: NeogenRoles[],
    // allRoles: any,
    setRoles: any;
    saveUser: (
        isEdit: boolean,
        email: string,
        name: string,
        company: number,
        firstName: string,
        lastName: string,
        phone: string,
        userBeingEdited: ErcUser | null,
        stPass: string,
        stUrl: string,
        stCompany: number,
        password: string,
        cpa: string,
        manager: string,
        affiliate: string,
        taxAttorney: string,
        roleGroup?: RoleGroup,
        clientStatus?: number,
    ) => void;
    saving: boolean;
    companiesQuery: any;
    isEdit: boolean;
};

export default AddEditUserModal;
