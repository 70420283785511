/* eslint-disable prettier/prettier */
/*
 *
 *
 * @author Matthew Riddell <matt@neogen.ai>
 * @date 8/28/20, 10:13 PM
 *
 */

import Title from "../../../layout/Title";
import { useState, useEffect, useCallback } from "react";
import * as React from "react";
import AdminUserService from "../../../services/users.service";
import AuthService from "../../../services/auth.service";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import AddEditUserModal from "./modals/add-edit-user-modal";
import UserService from "../../../services/user.service";
import CompanyService from "../../../services/company.service";
import Main from "../../../layout/Main";
import DeleteUserModal from "./modals/delete-user-modal";
import Loader2 from "../../utilities/Loader2";
import PageDescription from "../../../layout/page-description";
import AddEditRoleGroupsModal from "./modals/add-edit-role-groups";
import ResetPassword from "./modals/reset-password";
import OptionsDropDown from "../../../layout/options-dropdown";
import { search as ssSearch } from "ss-search";
import roleGroupService from "../../../services/role-group.service";
import _, { debounce } from "lodash";
import Swal from "sweetalert2";
import { useRecoilState } from "recoil";
import { assumedUserAtom } from "../../../atoms/userAtom";
import { ErcUser } from "../../../typings/api/erc-user";
import { ClearERCUser } from "../../../typings/api/clear-erc-user";
import { RoleGroup } from "../../../typings/api/role-group";
import SearchField from "../../../layout/search-field";
import { useBreakpoint } from "../../../hooks/appMedia";
import { useNavigate } from "react-router";
import { CreateClientMagicLinkModal } from "../../../magic-links/components/create-client-magic-link-modal";
import { DevicePhoneMobileIcon, EnvelopeIcon, UserIcon } from "@heroicons/react/20/solid";
import TableNeogen from "../../../layout/table-neogen";
import { CreateAffiliateMagicLinkModal } from "../../../magic-links/components/create-affiliate-magic-link-modal";
import PrintPre from "../../../layout/print-pre";
import clientStatusService from "../../../services/client-status.service";

function Users({ darkMode }: { darkMode?: boolean }) {
    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [debouncedSearch, setDebouncedSearch] = useState("");
    const [assumedUser, setAssumedUser] = useRecoilState(assumedUserAtom);
    const [showingAddEditUser, setShowingAddEditUser] = useState(false);
    const [showingAddEditRoles, setShowingAddEditRoles] = useState(false);
    const [showingDeleteUser, setShowingDeleteUser] = useState(false);
    const [editUserTitle, setEditUserTitle] = useState("");
    const [userBeingEdited, setUserBeingEdited] = useState<ErcUser | null>(null);
    const [roleGroups, setRoleGroups] = useState<any>([]);
    const [newRoles, setNewRoles] = useState<any>([]);
    const [saving, setSaving] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [deleting, setDeleting] = useState(false);
    const [userBeingDeleted, setUserBeingDeleted] = useState<any>({});
    const queryCache = useQueryClient();
    const [becomeUserAllowed, setBecomeUserAllowed] = useState(false);
    const [deleteUserAllowed, setDeleteUserAllowed] = useState(false);
    const [resetPasswordAllowed, setResetPasswordAllowed] = useState(false);
    const [canIAccess, setCanIAccess] = useState(false);
    const [canIAccessDefaultUser, setCanIAccessDefaultUser] = useState(false);
    const [showReset, setShowReset] = useState(false);
    const [showDefaultUser, setShowDefaultUser] = useState(false);
    const [companyId, setCompanyId] = useState(0);
    const [showCustomerMagicLinkModal, setShowCustomerMagicLinkModal] = useState(false);
    const [showAffiliateMagicLinkModal, setShowAffiliateMagicLinkModal] = useState(false);

    useEffect(() => {
        AuthService.canIAccess("BECOME_USER").then((r) => {
            setBecomeUserAllowed(r);
        });
        AuthService.canIAccess("RESET_PASSWORD").then((r) => {
            setResetPasswordAllowed(r);
        });
        AuthService.canIAccess("DELETE_USER").then((r) => {
            setDeleteUserAllowed(r);
        });
        AuthService.canIAccess("EDIT_DEFAULT_USER").then((r) => {
            setCanIAccessDefaultUser(r);
        });
    }, []);

    const settingsQuery = useQuery(["settings"], async () => {
        const response = await UserService.getSettingsByAllUserID();
        if (response) {
            return response.data;
        }
    });

    const clientStatusQuery = useQuery(["clientStatus"], async () => {
        const response = await clientStatusService.getAll();
        if (response) {
            return response.data;
        }
    });

    const getSetting = useCallback(
        (userId: string, settingName: string) => {
            if (settingsQuery.isSuccess) {
                return (
                    settingsQuery.data?.find((s: any) => s.settingName === settingName && s.userId === userId) ?? null
                );
            }
        },
        [settingsQuery.data, settingsQuery.isSuccess],
    );

    const usersQuery = useQuery(["users", "sorted", "date"], async () => {
        const response = await AdminUserService.getAll();
        if (response) {
            return response.data;
        }
    });
        // return response?.data || [];
    // });

    const users = React.useMemo(
        () =>
            debouncedSearch
                ? (ssSearch(
                      usersQuery.data || [],
                      ["firstName", "lastName", "email", "phone", "name", "id", "clientStatus"],
                      debouncedSearch,
                  ) as ClearERCUser[])
                : usersQuery.data || [],
        [debouncedSearch, usersQuery.data],
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const updateDebouncedSearch = useCallback(
        debounce(
            (term) => {
                setDebouncedSearch(term);
            },
            700,
            { trailing: true, maxWait: 1000 },
        ),
        [],
    );

    useEffect(() => {
        updateDebouncedSearch(search);
    }, [search, updateDebouncedSearch]);

    const companiesQuery = useQuery(
        ["allCompanies"],
        async () => {
            const response = await CompanyService.getAll();
            if (response) {
                return response.data;
            }
        },
        {
            cacheTime: 60000,
            staleTime: 60000,
            refetchOnMount: true,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
        },
    );

    async function saveUser(
        saveIsEdit: boolean,
        email: string,
        name: string,
        company: number,
        firstName: string,
        lastName: string,
        phone: string,
        user: ErcUser | null,
        stPass: string,
        stUrl: string,
        stCompany: number,
        password: string,
        cpa: string,
        manager: string,
        affiliate: string,
        taxAttorney: string,
        roleGroup?: RoleGroup,
        clientStatus?: number
    ) {
        if (!user && saveIsEdit) {
            return;
        }
        setSaving(true);
        if (!saveIsEdit) {
            // Add User
            const userId = await saveNewUser(
                email,
                name,
                company,
                firstName,
                lastName,
                phone,
                stPass,
                stUrl,
                newRoles,
                stCompany,
                password,
                cpa,
                manager,
                affiliate,
                taxAttorney,
                clientStatus,
            );
            if (userId && roleGroup) {
                await roleGroupService.postURL("role-group-users", {
                    clearErcUserId: userId,
                    roleGroupsId: roleGroup.id,
                });
            }
        } else {
            if (!user) {
                throw new Error("User is null");
            }
            await saveEditUser(
                user,
                name,
                email,
                company,
                firstName,
                lastName,
                phone,
                cpa,
                manager,
                affiliate,
                taxAttorney,
                clientStatus??-1,
            );
            if (user.id && roleGroup) {
                await roleGroupService.postURL("role-group-users", {
                    clearErcUserId: user.id,
                    roleGroupsId: roleGroup.id,
                });
            }
        }
    }

    async function saveRoleGroups(
        saveIsEdit: any,
        newRoles: any[],
        email: any,
        name: any,
        company: any,
        user: any = null,
        oldRoles: any = null,
        stPass = "",
        stUrl = "",
    ) {
        setSaving(true);
        await saveEditRoleGroups(oldRoles, newRoles, setSaving, setShowingAddEditUser, roleGroups, user);

        setSaving(false);
        setShowingAddEditRoles(false);
    }

    function handleCloseDeleteModal() {
        setShowingDeleteUser(false);
    }

    async function showAddEditUser(id: string | null) {
        if (id !== null) {
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }

        setSaving(false);
        setUserBeingEdited(null);
        setRoleGroups(null);
        setNewRoles(null);

        if (id !== null) {
            AdminUserService.getOne(id).then((r) => {
                setUserBeingEdited(r?.data ?? null);
                setShowingAddEditUser(true);
                setEditUserTitle(id);
            });
        } else {
            setRoleGroups([]);
            setUserBeingEdited(null);
            setShowingAddEditUser(true);
        }
    }
    async function showAddEditRoles(id: string, user: any) {
        if (id !== null) {
            setIsEdit(true);
        } else {
            setIsEdit(false);
        }

        setSaving(false);
        setUserBeingEdited(null);
        setRoleGroups(null);
        setNewRoles(null);
        setShowingAddEditRoles(true);
        if (id !== null) {
            setUserBeingEdited(user);

            AdminUserService.getURL("clear-erc-users/" + id + "/role-groups").then((r) => {
                if (r) {
                    console.error(r);
                    setRoleGroups(r.data);
                    setNewRoles(r.data);
                }
            });

            setEditUserTitle(id);
        } else {
            setRoleGroups([]);
            setUserBeingEdited(null);
        }
    }

    async function invalidateCache() {
        const r = await queryCache.invalidateQueries(["users"]);
    }

    function deleteUser() {
        setDeleting(true);
        AdminUserService.deleteByID(userBeingDeleted.id).then(async (r) => {
            setDeleting(false);
            setShowingDeleteUser(false);
            await invalidateCache();
        });
    }

    function hideEditUser() {
        setShowingAddEditUser(false);
    }
    function hideEditRoles() {
        setShowingAddEditRoles(false);
    }

    useEffect(() => {
        AuthService.canIAccess("ADD_USER").then((r) => {
            setCanIAccess(r);
        });
    }, []);

    async function saveEditUser(
        user: ClearERCUser,
        name: any,
        email: any,
        company: any,
        firstName: string,
        lastName: string,
        phone: string,
        cpa: string,
        manager: string,
        affiliate: string,
        taxAttorney: string,
        clientStatus: number,
    ) {
        if (user) {
            user.name = name ?? "";
            user.email = email;
            user.company = company;
            user.accountManagerUserId = manager;
            user.taxAttorneyUserId = taxAttorney;
            user.cpaUserId = cpa;
            user.affiliateUserId = affiliate;
            user.phone = phone ?? "";
            user.firstName = firstName ?? "";
            user.lastName = lastName ?? "";
            user.clientStatus = clientStatus ?? -1;
            delete user.lastLoggedIn;
            delete user.password;
            delete user.emailVerified;
            delete user.verificationToken;
            delete user.realm;
            delete user.roleGroups;
            const patch = await AdminUserService.patchURL("users/" + user.id, user);
            await invalidateCache();

            setSaving(false);
            setShowingAddEditUser(false);
        }
    }

    async function saveEditRoleGroups(
        oldRoles: any,
        newRoles: any[],
        setSaving: React.Dispatch<React.SetStateAction<boolean>>,
        setShowingAddEditUser: React.Dispatch<React.SetStateAction<boolean>>,
        roles: any,
        user: any,
    ) {
        const removed = oldRoles?.filter((x: any) => !newRoles.includes(x));
        const added = newRoles.filter((x) => !oldRoles?.includes(x));
        if (_.isEqual(oldRoles, newRoles)) {
            setSaving(false);
            setShowingAddEditUser(false);
        } else {
            // Delete anything that was removed
            await Promise.all(
                removed.map(async (role: any) => {
                    // Find the role id with this user and this role id
                    const filter = {
                        where: {
                            roleGroupsId: role.id,
                            clearErcUserId: user.id,
                        },
                    };
                    const r = await roleGroupService.getURL(
                        "role-group-users?filter=" + encodeURIComponent(JSON.stringify(filter)),
                    );
                }),
            );

            await Promise.all(
                added.map(async (role) => {
                    const ar = await roleGroupService.postURL("role-group-users", {
                        clearErcUserId: user.id,
                        roleGroupsId: role.id,
                    });
                }),
            );
            queryCache.invalidateQueries(["role-groups"]);
            Swal.fire({
                title: "Success",
                text: "User roles updated",
                icon: "success",
                showConfirmButton: false,
                timer: 2000,
            });
        }
    }

    async function saveNewUser(
        email: any,
        name: any,
        company: any,
        firstName: string,
        lastName: string,
        phone: string,
        stPass: string,
        stUrl: string,
        newRoles: any[],
        stCompany: number,
        password: string,
        cpa: string,
        manager: string,
        affiliate: string,
        taxAttorney: string,
        clientStatus?: number,
    ) {
        const newUser: ClearERCUser = {
            email: email,
            name: name,
            company: company,
            firstName: firstName,
            lastName: lastName,
            phone: phone,
            stPass: stPass,
            stUrl: stUrl,
            emailVerified: true,
            stCompany: stCompany,
            password: password,
            cpaUserId: cpa || undefined,
            accountManagerUserId: manager || undefined,
            affiliateUserId: affiliate || undefined,
            taxAttorneyUserId: taxAttorney || undefined,
        };
        const result = await AdminUserService.create(newUser).catch((e) => {
            console.log(e);
        });
        if (!result?.data) {
            return;
        }
        await invalidateCache();
        const newId = result.data.id;

        setSaving(false);
        setShowingAddEditUser(false);

        return newId;
    }

    async function resetPassword(user?: ClearERCUser) {
        if (!user) {
            return;
        }
        setUserBeingEdited(user);
        setShowReset(true);
    }

    const breakpoints = useBreakpoint();
    const isMobile = breakpoints.breakpoint === "mobile";
    const isTablet = breakpoints.breakpoint === "tablet";

    return (
        <>
            {showCustomerMagicLinkModal && (
                <CreateClientMagicLinkModal
                    onClose={() => {
                        setShowCustomerMagicLinkModal(false);
                    }}
                />
            )}
            {showAffiliateMagicLinkModal && (
                <CreateAffiliateMagicLinkModal
                    onClose={() => {
                        setShowAffiliateMagicLinkModal(false);
                    }}
                />
            )}
            <Main>
                <Title title="User Management" />
                <div className="">
                    <div className="">
                        {usersQuery.isLoading || companiesQuery.isLoading || clientStatusQuery.isLoading ? (
                            <div className={"mt-5 pt-5 mb-5 pb-5 text-center"}>
                                <Loader2 />
                            </div>
                        ) : (
                            <>
                                <PageDescription
                                    title="User Management"
                                    description="Manage users and their roles."
                                    type="users"
                                    buttons={[
                                        {
                                            label: "Create Customer Magic Link",
                                            onClick: () => setShowCustomerMagicLinkModal(true),
                                            disabled: !canIAccess,
                                            icon: "fas fa-user-plus",
                                        },
                                        {
                                            label: "Create Affiliate Magic Link",
                                            onClick: () => setShowAffiliateMagicLinkModal(true),
                                            disabled: !canIAccess,
                                            icon: "fas fa-user-plus",
                                        },
                                        {
                                            label: "Add User",
                                            onClick: () => showAddEditUser(null),
                                            disabled: !canIAccess,
                                            icon: "fas fa-user-plus",
                                        },
                                        {
                                            label: "Default User Details",
                                            onClick: () => setShowDefaultUser(true),
                                            disabled: !canIAccessDefaultUser,
                                            icon: "fas fa-user-plus",
                                        },
                                    ]}
                                >
                                    {/* <div className="inline-block w-96">
                    <InputControlled
                        className="w-full"
                        noMargin={true}
                        placeholder="Search for a customer"
                        // placeholder="Search"
                        value={search}
                        onChange={(e) => setSearch(e)}
                    />
                </div> */}
                                    <SearchField search={search} setSearch={setSearch} placeholder="Search users..." />
                                </PageDescription>

                                {/* >
                                <div className="sm:flex sm:items-center">
                                    <div className="sm:flex-auto">
                                        <p className="text-xl font-bold text-gray-600 sm:text-2xl dark:text-gray-400 mb-3">Users</p>
                                        <p className="mb-5">A list of all the users in your account including their name, title, email and role.</p>
                                    </div>
                                    {canIAccess && (
                                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                                            <button
                                                type="button"
                                                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                                                onClick={() => showAddEditUser(null)}
                                            >
                                                Add user
                                            </button>
                                        </div>
                                    )}
                                </div>
                            </PageDescription> */}
                                {!isMobile && !isTablet ? (
                                    <>
                                            <TableNeogen
                                                
                                            entries={users.map((user:ClearERCUser) => ({
                                                firstName: `${user.firstName} ${user.lastName}` || "-",
                                                email: user.email || "-",
                                                phone: user.phone || "-",
                                                name: user.name || "-",
                                                lastLoggedAt: user.lastLoggedIn || "-",
                                                id: user.id,
                                                clientStatus: user.clientStatus || -1,
                                            }))}
                                            ignoreFields={[
                                                "createdAt",
                                                "updatedAt",
                                                "defaultProcessflowId",
                                                "cpaUserId",
                                                "accountManagerUserId",
                                                "taxAttorneyUserId",
                                                "affiliateUserId",
                                                "password",
                                                "utm",
                                                "emailVerified",
                                                "company",
                                                "affiliateDetails",
                                                "closerId",
                                                "roles",
                                                "realm",
                                                "stPass",
                                                "stCompany",
                                                "stUrl",
                                                "verificationToken",
                                                "pf7percentage",
                                                "roleGroups",
                                            ]}
                                            headers={[
                                                "Name",
                                                "Email",
                                                "Phone",
                                                "Company Name",
                                                "Last Logged In",
                                                "Status",
                                            ]}
                                            formatters={[
                                                {
                                                    field: "phone",
                                                    type: "Phone",
                                                },
                                                {
                                                    field: "email",
                                                    type: "Email",
                                                },
                                                {
                                                    field: "lastLoggedIn",
                                                    type: "DateTime",
                                                },
                                                {
                                                    field: "clientStatus",
                                                    type: "Select",
                                                    options: clientStatusQuery.data?.map((s) => ({
                                                        name: s.name,
                                                        id: s.id,
                                                    })) || [],
                                                    onChange: async (id, value) => {
                                                        const user: ClearERCUser|undefined = usersQuery.data?.find((u:ClearERCUser) => u.id === id);
                                                        if (user) {
                                                            user.clientStatus = value;
                                                            delete user.password;
                                                            delete user.emailVerified;
                                                            delete user.verificationToken;
                                                            delete user.realm;
                                                            delete user.roleGroups;
                                                            const patch = await AdminUserService.patchURL("users/" + user.id, user);
                                                            await invalidateCache();

                                                            saveEditUser(user, user.name, user.email, user.company, user.firstName??"", user.lastName??"", user.phone??"", user.cpaUserId??"", user.accountManagerUserId??"", user.affiliateUserId??"", user.taxAttorneyUserId??"", value);
                                                        } 

                                                },

                                                }
                                            ]}
                                            actionsAreDropDown={true}
                                            actions={[
                                                {
                                                    label: "Edit User",
                                                    onClick: (id) => showAddEditUser(id ?? ""),
                                                    icon: "fal fa-pencil",
                                                },
                                                {
                                                    label: "Open application",
                                                    onClick: (id) => {
                                                        navigate(`/users/${id}/application`);
                                                    },
                                                    icon: "fal fa-file-text",
                                                },

                                                {
                                                    label: "Reset Password",
                                                    onClick: (id) =>
                                                        resetPassword(usersQuery.data?.find((u) => u.id === id)),
                                                    // action: () => resetPassword(person),
                                                    icon: "fal fa-recycle",
                                                    disabled: () => resetPasswordAllowed,
                                                },

                                                
                                                {
                                                    label: "Edit Role Groups",
                                                    onClick: (id) => {
                                                        const user = usersQuery.data?.find((u) => u.id === id);
                                                        if (user) {
                                                            showAddEditRoles(user.id ?? "", user);
                                                        }
                                                    },
                                                    icon: "fal fa-lock",
                                                },
                                                {
                                                    label: "Delete User",
                                                    onClick: (id) => {
                                                        const user = usersQuery.data?.find((u) => u.id === id);
                                                        if (user) {
                                                            setUserBeingDeleted(user);
                                                            setShowingDeleteUser(true);
                                                        }
                                                    },
                                                    icon: "fad fa-trash !text-red-500 ",
                                                    disabled: () => !deleteUserAllowed,
                                                },
                                            ]}
                                        />
                                    </>
                                ) : (
                                    <ul>
                                        {users.map((person: ClearERCUser) => (
                                            <li
                                                key={person.id}
                                                className="bg-gray-50 dark:bg-gray-700 dark:border-gray-900 p-4 rounded-lg shadow-md mb-4"
                                            >
                                                <div className="flex items-start justify-between">
                                                    <div>
                                                        <div className="flex gap-2 pb-1">
                                                            <UserIcon
                                                                color={darkMode ? "#4ade80" : "#4338ca"}
                                                                className="w-5 h-5"
                                                            />
                                                            <span>{person.name ? person.name : "Unknown"}</span>
                                                        </div>
                                                        {/* <div className="flex gap-2 pb-1">
                                                            <BriefcaseIcon className="w-5 h-5" />{" "}
                                                            <span>{person.company}</span>
                                                        </div> */}
                                                        <div className="flex gap-2 pb-1">
                                                            <EnvelopeIcon
                                                                color={darkMode ? "#4ade80" : "#4338ca"}
                                                                className="w-5 h-5"
                                                            />
                                                            <span>{person.email ? person.email : "Unknown"}</span>
                                                        </div>
                                                        <div className="flex gap-2 pb-1">
                                                            <DevicePhoneMobileIcon
                                                                color={darkMode ? "#4ade80" : "#4338ca"}
                                                                className="w-5 h-5"
                                                            />
                                                            <span>{person.phone ? person.phone : "Unknown"}</span>
                                                        </div>
                                                    </div>
                                                    <OptionsDropDown
                                                        asEllipses
                                                        options={[
                                                            {
                                                                label: "Edit User",
                                                                action: () => showAddEditUser(person.id ?? ""),
                                                                icon: "fa fa-pencil",
                                                            },
                                                            {
                                                                label: "Become User",
                                                                action: () => setAssumedUser(person),
                                                                icon: "fa fa-user",
                                                            },
                                                            resetPasswordAllowed
                                                                ? {
                                                                      label: "Reset Password",
                                                                      action: () => resetPassword(person),
                                                                      icon: "fa fa-recycle",
                                                                  }
                                                                : null,
                                                            deleteUserAllowed
                                                                ? {
                                                                      label: "Delete User",
                                                                      action: () => {
                                                                          setUserBeingDeleted(person);
                                                                          setShowingDeleteUser(true);
                                                                          // user.id;
                                                                      },
                                                                      icon: "fa fa-trash",
                                                                  }
                                                                : null,
                                                            {
                                                                label: "Edit Role Groups",
                                                                action: () => showAddEditRoles(person.id ?? "", person),
                                                                icon: "fa fa-lock",
                                                            },
                                                        ]}
                                                    />
                                                </div>
                                                <div className="flex justify-start w-full mt-2">
                                                    <div className="text-xs font-light italic">
                                                        Last seen:{" "}
                                                        <span>
                                                            {person.lastLoggedIn
                                                                ? new Date(person.lastLoggedIn).toLocaleDateString()
                                                                : "Unknown"}
                                                        </span>
                                                    </div>
                                                </div>
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </Main>
            <DeleteUserModal
                show={showingDeleteUser}
                handleClose={handleCloseDeleteModal}
                deleteUser={deleteUser}
                deleting={deleting}
                userBeingDeleted={userBeingDeleted}
            />
            <AddEditUserModal
                show={showingAddEditUser}
                handleClose={hideEditUser}
                userBeingEdited={userBeingEdited}
                setRoles={setNewRoles}
                companiesQuery={companiesQuery}
                saveUser={saveUser}
                saving={saving}
                isEdit={isEdit}
            />
            <AddEditRoleGroupsModal
                show={showingAddEditRoles}
                roleGroups={roleGroups}
                handleClose={hideEditRoles}
                userBeingEdited={userBeingEdited}
                setRoles={setNewRoles}
                companiesQuery={companiesQuery}
                saveRoleGroups={saveRoleGroups}
                saving={saving}
                isEdit={isEdit}
            />
            <ResetPassword show={showReset} close={() => setShowReset(false)} user={userBeingEdited} />
            {/* <FlowrouteCredentialsModal show={showFlowrouteCredentials} close={() => setShowFlowrouteCredentials(false)} companyId={companyId} /> */}
        </>
    );
}

export default Users;
