import Swal from "sweetalert2";
import { downloadFile } from "../sections/utilities/download-file";
import fileService from "../services/file.service";
import { FileUpload, FileUploadWithMultiple } from "../typings/api/file-upload";
import ButtonNeoGen from "./button";
import * as Sentry from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";

export default function FileUploadWidgetEntry({
    // deleteFile,
    onFileChange,
    setIsReplacing,
    file,
    newFileDetails,
    setNewFileDetails,
    passUpFileDetails,
}: FileUploadWidgetEntryProps) {
    const queryCache = useQueryClient();

    async function deleteFile(file: FileUpload): Promise<void> {
        try {
            // Delete By ID will set the file to archived
            fileService.deleteByID(file.id).then(() => {
                // Update the processflow progress to reflect the deletion
                const existingArray = [...newFileDetails.multipleFiles];
                const index = existingArray.findIndex((x) => x.id === file.id);
                existingArray.splice(index, 1);
                setNewFileDetails({
                    multipleFiles: existingArray,
                    hasMultiple: true,
                });
                // file.isArchived = true;

                passUpFileDetails({
                    multipleFiles: existingArray,
                    hasMultiple: true,
                });
                setIsReplacing(false);
                queryCache.invalidateQueries(["files"]);
                Swal.fire({
                    text: "File deleted successfully",
                    icon: "success",
                    showCancelButton: false,
                    // showOkButton: true,
                    // cancelButtonColor: "#3085d6",
                    // cancelButtonText: "No, cancel!",
                    showConfirmButton: false,
                    timer: 2000,
                });
            });
        } catch (error) {
            console.error(error);
            Sentry.captureException(error);
        }
    }

    if (file.isArchived) {
        return <></>;
    }

    return (
        <>
            <div className="my-auto border dark:border-slate-600 mx-auto p-2 rounded-lg bg-white dark:bg-slate-800 shadow overflow-none">
                <div className="grid gap-5">
                    <div className="grid-cols-1 flex flex-auto gap-5">
                        <div className="grow my-auto break-all"> {file.originalFilename}</div>
                        <ButtonNeoGen
                            // type="warning"
                            size="xs"
                            className="shrink my-auto bg-indigo-700 text-slate-200"
                            // icon="fas fa-download"
                            iconAlign="left"
                            text="Download"
                            onClick={() => {
                                return downloadFile(file?.filename ?? "", file?.originalFilename ?? "");
                            }}
                        >
                            {/* Download File */}
                        </ButtonNeoGen>
                        <ButtonNeoGen
                            type="danger"
                            icon="fas fa-times"
                            iconAlign="center"
                            className="-mr-1 -mt-1 h-5 w-5 text-center ml-auto mb-auto relative top-0 right-0 bg-red-500"
                            onClick={() => {
                                // Use sweetalert to confirm delete
                                Swal.fire({
                                    text: "Are you sure you want to delete this file?",
                                    icon: "warning",
                                    showCancelButton: true,
                                    // cancelButtonColor: "#3085d6",
                                    confirmButtonColor: "#d33",
                                    confirmButtonText: "Yes, delete it!",
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        // Delete file
                                        // setIsReplacing(true);
                                        deleteFile(file).then(() => {
                                            Swal.fire({
                                                title: "Success",
                                                text: "File deleted",
                                                icon: "success",
                                                showConfirmButton: false,
                                                timer: 1500,
                                            });
                                        });
                                    } else {
                                        // Do nothing
                                    }
                                });
                            }}
                            // onClick={() => setIsReplacing(true)}
                            // text="Delete"
                            size="xxs"
                        />
                    </div>
                </div>
            </div>
        </>
    );
}

type FileUploadWidgetEntryProps = {
    file: FileUpload;
    setIsReplacing: (isReplacing: boolean) => void;
    newFileDetails: {
        multipleFiles: FileUpload[];
        hasMultiple: true;
    };
    passUpFileDetails: (fileUploadWithMultiple: FileUploadWithMultiple) => void;
    setNewFileDetails: (newFileDetails: { multipleFiles: FileUpload[]; hasMultiple: true }) => void;

    // deleteFile: (file: FileUpload) => Promise<boolean>;
    onFileChange: (file: File) => void;
};
